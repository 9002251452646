<template>
  <div class="pt-4 pb-2">
    <router-link
      to="/mailbox"
      class="back-link"
    >
      <v-icon size="24px">
        navigate_before
      </v-icon>
      CEO mailbox
    </router-link>

    <h1 class="dark-text font-weight-semi-bold fz-28 mb-4">Analytics</h1>
    <span class="mr-auto px-0 pb-1 pt-0 font-weight-semi-bold fz-16">
      Incoming calls for number
    </span>
    <v-card class="px-4 pb-5 pt-2 mb-5">
      <v-row>
        <v-col order="2" order-md="1" class="px-2" cols="6" md="auto">
          <v-select
            v-model="incomingNumber"
            :items="['All numbers']"
            offset-y
            solo
            outlined
            class="font-weight-regular mb-n3 fz-14"
            hide-details
            height="36px"
            style="max-width: 130px"
          >
            <template v-slot:append>
              <v-icon size="14px" color="#637381">unfold_more</v-icon>
            </template>
          </v-select>
        </v-col>
        <v-col order="3" order-md="2" class="px-2" cols="6" md="3">
          <v-select
            v-model="incomingNumberType"
            :items="['Connected', 'Attempts']"
            menu-props="offsetY"
            solo
            outlined
            hide-details
            class="font-weight-regular mb-n3 fz-14"
            height="36px"
          >
            <template v-slot:append>
              <v-icon size="14px" color="#637381">unfold_more</v-icon>
            </template>
          </v-select>
        </v-col>
        <v-col order="4" order-md="3" cols="12" md="auto">
          <date-picker class="ml-2"/>
        </v-col>
        <v-col order="1" order-md="4" cols="12">
          <line-chart :data="analytics.incomingNumbers" :options="options" style="height: 280px"/>
        </v-col>
      </v-row>
    </v-card>
    <span class="mr-auto px-0 pb-1 pt-0 font-weight-semi-bold fz-16 d-flex">Incoming calls for member</span>
    <v-card class="px-4 pb-5 pt-2">
      <v-row>
        <v-col order="2" order-md="1" class="px-2" cols="6" md="auto">
          <SelectComponent :label="true" :value="allUsers" @selectedValue="incomingMember" />
        </v-col>
        <v-col order="3" order-md="2" class="px-2" cols="6" md="3">
          <v-select
            v-model="incomingMemberType"
            :items="['Connected', 'Attempts']"
            menu-props="offsetY"
            solo
            outlined
            hide-details
            class="font-weight-regular mb-n3 fz-14"
            height="36px"
          >
            <template v-slot:append>
              <v-icon size="14px" color="#637381">unfold_more</v-icon>
            </template>
          </v-select>
        </v-col>
        <v-col order="4" order-md="3" class="px-2" cols="12" md="auto">
          <date-picker class="ml-2"/>
        </v-col>
        <v-col order="1" order-md="4" cols="12">
          <line-chart :data="analytics.incomingMembers" :options="options" style="height: 280px"/>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import LineChart from '@/components/LineChart'
import DatePicker from '@/components/DatePicker'
import SelectComponent from '@/components/SelectComponent'
import { mapActions } from 'vuex'

export default {
  name: 'Analytics',
  components: {
    LineChart,
    DatePicker,
    SelectComponent
  },
  data: () => ({
    incomingNumber: 'All numbers',
    incomingNumberType: 'Attempts',
    incomingNumberDates: '',
    incomingMemberType: 'Connected',
    incomingMemberDates: '',
    incomingMember: '',
    analytics: {
      incomingNumbers: {
        labels: ['Sep 1', 'Sep 2', 'Sep 3', 'Sep 4', 'Sep 5', 'Sep 6', 'Sep 7', 'Sep 8', 'Sep 9', 'Sep 10', 'Sep 11', 'Sep 12'],
        datasets: [
          {
            backgroundColor: 'transparent',
            borderColor: '#9C6ADE',
            data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11],
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#9C6ADE'
          }
        ]
      },
      incomingMembers: {
        labels: ['Sep 1', 'Sep 2', 'Sep 3', 'Sep 4', 'Sep 5', 'Sep 6', 'Sep 7', 'Sep 8', 'Sep 9', 'Sep 10', 'Sep 11', 'Sep 12'],
        datasets: [
          {
            backgroundColor: 'transparent',
            borderColor: '#47C1BF',
            data: [4, 2, 32, 9, 11, 42, 9, 8, 4, 2, 1, 11],
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#47C1BF'
          }
        ]
      }
    },
    options: {
      maintainAspectRatio: false,
      elements: {
        line: {
          tension: 0
        }
      },
      legend: {
        display: false
      }
    },
    allUsers: []
  }),
  created () {
    this.loadMembers()
  },
  methods: {
    ...mapActions(['getMembers']),
    async loadMembers () {
      try {
        const { data } = await this.getMembers()
        const logTypes = [
          {
            label: 'All users',
            value: ''
          }
        ]
        data.members.forEach(item => {
          logTypes.push({
            label: `${item.firstName} ${item.lastName}`,
            value: item.uuid
          })
        })
        this.allUsers = logTypes
      } catch (err) {
        throw new Error(err)
      }
    }
  }
}
</script>
<style scoped lang="scss">
  ::v-deep .v-list-item__content .v-list-item__title {
    font-size: 14px !important;
  }
</style>
